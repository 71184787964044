import React from 'react';
import '../styles.css'

import MonitoringImg from '../../components/services/monitoringImg.jpg'

function Monitoring(props) {
    document.title = "Monitoring";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Monitoring<hr className="headerLine"></hr></div>
        <div className="paragraph4">
        We provide monitoring of commercial Fire Alarm Systems and Security Alarm
        Systems utilizing both traditional phone lines and cellular technologies.
        </div>

        <div className="bottomImg"> <img className="images" src={MonitoringImg} alt="Monitoring" width="40%"></img></div>

        </p>
    </body>
    );
}

export default Monitoring;