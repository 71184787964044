import React from 'react';
import './styles.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../App.css';

function NavBar() {
   return (
      <Navbar className="navbar" collapseOnSelect variant="dark">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mv-auto">
          <NavDropdown className="dropdownlinks" title="Products" id="collapsible-nav-dropdown">
            <NavDropdown.Item href="/firealarm">Fire Alarm</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/cameras">Cameras</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/accesscontrol">Access Control</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/intercom">Intercom Systems</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/publicaddress">Public Address</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/avsystems">A/V Systems</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/nursecall">Nurse Call</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/telephone">Telephone</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/networking">Network Cabling</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/wirelesscomm">Wireless Communications</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown className="dropdownlinks" title="Markets" id="collapsible-nav-dropdown">
            <NavDropdown.Item href="/educational">Educational</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/healthcare">Healthcare</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/government">Government</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/religious">Religious</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/institutional">Institutional</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/business">Business</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="retail">Retail</NavDropdown.Item>
          </NavDropdown>
  
          <NavDropdown className="dropdownlinks" title="Services" id="collapsible-nav-dropdown">
            <NavDropdown.Item href="/installation">Installation</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/repair">Repair</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/inspection">Inspection</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/monitoring">Monitoring</NavDropdown.Item>
          </NavDropdown>
  
          <NavDropdown className="dropdownlinks" title="About Us" id="collapsible-nav-dropdown">
            <NavDropdown.Item href="/findus">Find Us (MAP)</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/ourteam">Our Team</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/certifications">Certifications</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/organizations">Organizations</NavDropdown.Item>
          </NavDropdown>
  
          <Nav.Link href="careers">Careers</Nav.Link>
         
        </Nav>
        <Nav>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
   
   );
}

export default NavBar;