import React from 'react';
import '../styles.css'

import RepairImg from '../../components/services/repairImg.jpg'

function Repair(props) {
    document.title = "Repairs";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Service & Repair<hr className="headerLine"></hr></div>
        <div className="paragraph4 ">
        With a fully staffed service department we repair almost any system. Contact our
        service department for an assessment of your needs.
        </div>

        <div className="bottomImg"> <img className="images" src={RepairImg} alt="Repair" width="40%"></img></div>

        </p>
    </body>
    );
}

export default Repair;