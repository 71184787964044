import React from 'react';
import '../styles.css'

import SFMlogo from '../../components/icons/SFMlogo.png';
import LSBClogo from '../../components/icons/LSBClogo.png';
import NICETlogo from '../../components/icons/NICETlogo.png';
import BICSIlogo from '../../components/icons/BICSIlogo.png';

function Certifications(props) {
    document.title = "Certifications";
    return (
    <body className="subBody">
        <p>
        <div className="heading">Certifications<hr className="headerLine"></hr></div>
        <div className="pageBody">
            <div className="paragraph4">
        AAR Electronics keeps up-to-date with the latest certifications regarding our installation and inspections to ensure that your site remains safe, functional, and adheres to the state's regulations. Below are several contacts in which we obtain various licenses and certifications from.</div><br></br>
        <table className="certTable">
            <tr>
                <td className="certCell"><div className="tableHead"> Louisiana State Fire Marshall - License No. F2</div><hr></hr>
                <i>Life Safety<br></br>
                Property Protection</i>
                <br></br><br></br>
                <a className="contentLinks" href="http://sfm.dps.louisiana.gov/">Website</a></td>
                <td className="certCell2"><img src={SFMlogo} alt="SFM Logo" width="50%" height="auto"/></td>
            </tr>
            <tr>
                <td className="certCell"><div className="tableHead"> Lousiana State Board for Contractors</div><hr></hr>
                <i>Electrical Work Statewide</i><br></br>
                Lisence No: 31798
                <br></br><br></br>
                <a className="contentLinks" href="http://www.lslbc.louisiana.gov/">Website</a></td>
                <td><img src={LSBClogo} alt="LSBC Logo" width="90%" height="auto"/></td>
            </tr>
            <tr>
                <td className="certCell"><div className="tableHead"> National Institute for Certification in Engineering Technologies</div><hr></hr>
                NICET Certified Company
                <br></br><br></br>
                <a className="contentLinks" href="https://www.nicet.org/">Website</a></td>
                <td className="certCell2"><img src={NICETlogo} alt="NICET Logo" width="50%" height="auto"/></td>
            </tr>
            <tr>
                <td className="certCell"><div className="tableHead"> Building Industry Consulting Service International</div><hr></hr>
                BICSI Certified Company
                <br></br><br></br>
                <a className="contentLinks" href="https://www.bicsi.org/">Website</a></td>
                <td className="certCell2"><img src={BICSIlogo} alt="BICSI Logo" width="70%" height="auto"/></td>
            </tr>
        </table>
        </div>
        </p>
    </body>
    );
}

export default Certifications;