import React from 'react';
import '../styles.css'

import RetailImg from '../../components/markets/retail.jpg'

function Retail(props) {
    document.title = "Retail";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Retail<hr className="headerLine"></hr></div>
        <div className="paragraph4">
        The following are examples of the services we have provided on various retailer stores and malls throughout the state.<br></br>
        <b><u>If the service you need does not fall under these categories, please contact us for inquiries.</u></b>
        </div>
        <table className="infoTable">
            <tr><td className="productCell4">
        <ul className="marketList">
            <li> <a href="telephone">Telephone Systems</a></li>
            <li> <a href="\intercom">Intercom Systems</a></li>
            <li> <a href="\cameras">Camera Systems</a></li>
            <li> <a href="\accesscontrol">Access Control Systems</a></li>
        </ul>
            </td>
            <td className="productImg"><img className="images" src={RetailImg} alt="Retail" width="70%"></img></td>
            </tr>
        </table>
        </p>
    </body>
    );
}

export default Retail;