import React from 'react';
import './styles.css';
import { Form, Button } from 'react-bootstrap';

import application from '../components/Employment Application.pdf';

function CareerPage(props) {
    document.title = "Careers";
    return (
        <body className="subBody">
            <p>
                <div className="heading">Career Opportunities</div><hr className="headerLine"></hr>
                <div className="paragraph4">A A R Electronics offers a wide variety of jobs to help master any career goals you may want to conquer.  We strive to give our employees the competitive,
                                    fast-paced work environment they truly desire. We expect all our employees to conduct business with the utmost respect.
                 Our team members are among the elite of their job profession and strive to take on every job as a new experience. </div>

                 <div className="productHead">Applying Online<hr className="prouctDiv"></hr></div>
                <div className="paragraph3">To submit an online application, please fill out our job application online via Google Forms by clicking the button below.<br></br><br></br>
                <a class="btn btn-primary btn-lg" href="https://docs.google.com/forms/d/e/1FAIpQLSdMnlZva454Ss8w-T_g9gJF-G37BuCWBI06xdcCbB0-t4_ybA/viewform?usp=sf_link" role="button">Click here to Apply Online</a></div>  
                <hr></hr>
                <div className="paragraph3">Alternatively, a <a href={application} download>PDF version</a> of the application is also available. All PDF applications must be printed and filled out, and can be submitted either by fax or at our office location.</div>  
                        
        </p>
  </body>

            );
       }
export default CareerPage;