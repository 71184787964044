import React from 'react';
import '../styles.css'

import CARE160 from '../../components/products/CARE160.png'
import CARE120 from '../../components/products/CARE120.png'
import CallSwitch from '../../components/products/CallSwitch.jpg'
import WanderManagement from '../../components/products/WanderManagement.png'

function NurseCall(props) {
    document.title = "Nurse Call";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Nurse Call Systems<hr className="headerLine"></hr></div>
        <div className="pageBody">
        <div className="productHead">TekTone<hr className="productDiv"></hr></div>
            <table className="infoTable">
                <tr>
                    <td className="productCell">
                    <div className="product">Tek-CARE160</div>
                        <div>The Tek-CARE160 Two-Way Audio Communication Nurse Call System is a modern and affordable nurse
                        call system that is easy to install, use, and maintain. Featuring customizable peripheral devices, sleek,
                        touchscreen master stations and high-quality two-way voice communication, the Tek-CARE160 is
                        designed to meet the needs of facilities across the entire healthcare spectrum.<br></br>
                            <a className="contentLinks" href="https://www.tektone.com/tek-care160/">Product Link</a></div>
                    </td>
                    <td className="productImg"><img src={CARE160} alt="CARE160" width="80%"></img></td>
                </tr>
                <tr>
                    <td className="productCell">
                    <div className="product">Tek-CARE120</div>
                        <div>The Tek-CARE120 Tone-Visual Nurse Call System is an aesthetically pleasing and surprisingly affordable
                        nurse call system designed for easy installation and years of trouble-free operation in a variety of
                        healthcare facilities, such as skilled nursing, senior living, assisted/independent living, urgent care,
                        clinics, and ambulatory surgical centers.<br></br>
                            <a className="contentLinks" href="https://www.tektone.com/products/nurse-call-systems/tek-care120/">Product Link</a></div>
                    </td>
                    <td className="productImg"><img src={CARE120} alt="CARE120" width="80%"></img></td>
                </tr>
                <tr>
                    <td className="productCell">
                    <div className="product">Emergency Switches</div>
                        <div>The SF341B Code Call Switch operates all system code signals, and is designed for use in ICU/CCU,
                            physical therapy rooms, post-op recovery, or any location where code call initiation may be required.<br></br>
                            <a className="contentLinks" href="https://www.tektone.com/pdf_files/IL510_SF341B_spec.pdf">Product Link</a></div>
                    </td>
                    <td className="productImg"><img src={CallSwitch} alt="Call Switch" width="60%"></img></td>
                </tr>
                <tr>
                    <td className="productCell">
                    <div className="product">Wander Management</div>
                        <div>The Tek-CARE®700 Wander Management System gives Alzheimer&#39;s, dementia and other at-risk residents
                        the ability to move freely about your facility while receiving the protection they need. It allows staff to
                        easily manage residents who might otherwise wander off or stray into dangerous areas, without
                        impeding the movement of staff and visitors.<br></br>
                            <a className="contentLinks" href="https://www.tektone.com/portfolio-view/2wander-mgmt-system/">Product Link</a></div>
                    </td>
                    <td className="productImg"><img src={WanderManagement} alt="Wander Management System" width="70%"></img></td>
                </tr>
        </table>
        </div>
        </p>
    </body>
    );
}

export default NurseCall;