import React from 'react';
import '../styles.css'

import FA300 from '../../components/products/fa300.jpg';
import FX3500 from '../../components/products/fx3500.jpg';
import QXMINI from '../../components/products/qxmini.jpg';


function FireAlarm(props) {
    document.title = "Fire Alarm";
    return (
    <body className="subBody">
        <p>
        <div className="heading">Fire Alarm Systems<hr className="headerLine"></hr></div>
        <div className="pageBody">
            <table className="infoTable">
                <tr><td><div className="productHead">Conventional Systems<hr className="prouctDiv"></hr></div></td></tr>
                <tr>
                    <td className="productCell">
                    <div className="product">FA-300</div>
                     Ideal for Sprinkler Monitoring and dedicated purpose Alarm Systems.<br></br>
                     <a className="contentLinks" href="https://mircom.com/product-listing/fire-alarm-detection/conventional-fire-alarm-systems/fa-300-series-lcd-fire-alarm-control-panels/">Product Link</a>
                    </td>
                    <td><img src={FA300} alt="FA300" width="70%"></img></td>
                </tr>
                <tr><td><div className="productHead">Addressable Systems<hr className="prouctDiv"></hr></div></td></tr>
                <tr>
                    <td className="productCell">
                    <div className="product">FX-3500</div>
                    Small addressable system can handle up to 159 addressable
                    devices. Ideal for Small Offices, Small Industrial Facilities, Medical facilities,
                    Churches or Recreation Centers.<br></br>
                    <a className="contentLinks" href="https://mircom.com/product-listing/fire-alarm-detection/addressable-fire-alarm/fx-3500/">Product Link</a>
                    <div className="product">Flex-Net</div>
                    Medium Systems capable of 2 addressable loops. Can handle
                    up to 318 addressable devices. Ideal for Small Hospitals, Nursing
                    Homes/Assistive living facilities, Schools, Small Arenas, or Hotels.<br></br>
                    <a className="contentLinks" href="https://mircom.com/product-listing/fire-alarm-detection/network-fire-alarm/flex-net/">Product Link</a>
                    </td>
                    <td><img src={FX3500} alt="FX3500" width="80%"></img></td>
                </tr>
                <tr><td><div className="productHead">Voice Evacuation<hr className="prouctDiv"></hr></div></td></tr>
                <tr>
                    <td className="productCell">
                    <div className="productInfo">Voice Evac Systems Enhance any fire alarm system with Voice EVAC and or MASS notification.</div>
                    <div className="product">QX-MINI</div>
                    Distributed Voice Evacuation System capable of 30 Watts of
                    Audio out of the box. Can be expanded with remote panels distributed
                    throughout a facility to handle all the facility’s needs. The QX-Mini features
                    customizable messages that can be initiate any emergency response.<br></br>
                    <a className="contentLinks" href="https://mircom.com/product-listing/fire-alarm-detection/voice-evacuation-systems/qx-mini/">Product Link</a>
                    </td>
                    <td><img src={QXMINI} alt="QXMINI" width="80%"></img></td>
                </tr>
                <tr><td><div className="productHead">Monitoring<hr className="prouctDiv"></hr></div></td></tr>
                <tr>
                    <td className="productCell">
                    <div className="productInfo">UL listed Remote station monitoring services for Fire Alarm Systems.</div>
                    <div className="product">Analog Phone Lines (Legacy)</div>
                    <div className="product">Cellular Monitoring</div>
                    </td>
                    <td></td>
                </tr>
        </table>
        </div>
        </p>
    </body>
    );
}

export default FireAlarm;