import React from 'react';
import '../styles.css'

import InstallationImg from '../../components/services/installationImg.jpg'

function Installation(props) {
    document.title = "Installations";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Installations<hr className="headerLine"></hr></div>
        <div className="paragraph4">
        Our services involve a fully-staffed installation department with installers and technicians licensed and
        trained in each particular area of expertise. We can provide a professionally
        installed system that would perform trouble free for years to come.
        </div>

        <div className="bottomImg"> <img className="images" src={InstallationImg} alt="Installation" width="30%"></img></div>

        </p>
    </body>
    );
}

export default Installation;