import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './components/Header';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import CareerPage from './pages/CareerPage';

import Certifications from './pages/About Us/Certifications';
import OurTeam from './pages/About Us/OurTeam';
import FindUs from './pages/About Us/FindUs';
import Organizations from './pages/About Us/Organizations';

import FireAlarm from './pages/Products/FireAlarm';
import AccessControl from './pages/Products/AccessControl';
import AVSystems from './pages/Products/AVSystems';
import Cameras from './pages/Products/Cameras';
import IntercomSystems from './pages/Products/IntercomSystem';
import NetworkCabling from './pages/Products/NetworkCabling';
import NurseCall from './pages/Products/NurseCall';
import PublicAddress from './pages/Products/PublicAddress';
import TelephoneSystems from './pages/Products/TelephoneSystems';
import WirelessComm from './pages/Products/WirelessComm';

import Business from './pages/Markets/Business';
import Educational from './pages/Markets/Educational';
import Government from './pages/Markets/Government';
import Healthcare from './pages/Markets/Healthcare';
import Institutional from './pages/Markets/Institutional';
import Religious from './pages/Markets/Religious';
import Retail from './pages/Markets/Retail';

import Inspection from './pages/Services/Inspection';
import Installation from './pages/Services/Installation';
import Monitoring from './pages/Services/Monitoring';
import Repair from './pages/Services/Repair'

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: 'A A R ELECTRONICS, INC.',
      headerLinks: [
        { title: 'Home', path: '/' },
        { title: 'About', path: '/about' },
        { title: 'Our Team', path: '/employees' },
        { title: 'Careers', path: '/careers' },
      ],
      home: {
        title: 'Protecting the Community, One Business at a Time',
        subTitle: 'One Business at a Time',
        text: ''
      },
      about: {
        title: 'About Me'
      },
      employees: {
        title: 'Let\'s Talk'
      },
      careers: {},
      products: {},

      firealarm: {},
      cameras: {},
      avsystems: {},
      accesscontrol: {},
      intercom: {},
      networking: {},
      nursecall: {},
      publicaddress: {},
      telephone: {},
      wirelesscomm: {},

      business: {},
      educational: {},
      government: {},
      healthcare: {},
      institutional: {},
      religious: {},
      retail: {},

      inspection: {},
      installation: {},
      monitoring: {},
      repair: {},

      certifications: {},
      findus: {},
      organizations: {},
      ourteam: {}
    }
  }

  render() {
    return (
      <Router>
        <Container className="Container p-0" fluid={true}>
          <Header />

          <NavBar />

          <Route path="/" exact render={() => <HomePage title={this.state.home.title} subTitle={this.state.home.subTitle} text={this.state.home.text} />} />
          <Route path="/careers" render={() => <CareerPage title={this.state.careers.title} />} />

          <Route path="/certifications" render={() => <Certifications title={this.state.certifications.title} />} />
          <Route path="/findus" render={() => <FindUs title={this.state.findus.title} />} />
          <Route path="/organizations" render={() => <Organizations title={this.state.organizations.title} />} />
          <Route path="/ourteam" render={() => <OurTeam title={this.state.ourteam.title} />} />

          <Route path="/firealarm" render={() => <FireAlarm title={this.state.firealarm.title} />} />
          <Route path="/avsystems" render={() => <AVSystems title={this.state.avsystems.title} />} />
          <Route path="/cameras" render={() => <Cameras title={this.state.cameras.title} />} />
          <Route path="/intercom" render={() => <IntercomSystems title={this.state.intercom.title} />} />
          <Route path="/networking" render={() => <NetworkCabling title={this.state.networking.title} />} />
          <Route path="/nursecall" render={() => <NurseCall title={this.state.nursecall.title} />} />
          <Route path="/publicaddress" render={() => <PublicAddress title={this.state.publicaddress.title} />} />
          <Route path="/telephone" render={() => <TelephoneSystems title={this.state.telephone.title} />} />
          <Route path="/wirelesscomm" render={() => <WirelessComm title={this.state.wirelesscomm.title} />} />
          <Route path="/accesscontrol" render={() => <AccessControl title={this.state.accesscontrol.title} />} />
         
          <Route path="/business" render={() => <Business title={this.state.business.title} />} />
          <Route path="/educational" render={() => <Educational title={this.state.educational.title} />} />
          <Route path="/government" render={() => <Government title={this.state.government.title} />} />
          <Route path="/healthcare" render={() => <Healthcare title={this.state.healthcare.title} />} />
          <Route path="/institutional" render={() => <Institutional title={this.state.institutional.title} />} />
          <Route path="/religious" render={() => <Religious title={this.state.religious.title} />} />
          <Route path="/retail" render={() => <Retail title={this.state.retail.title} />} />

          <Route path="/inspection" render={() => <Inspection title={this.state.inspection.title} />} />
          <Route path="/installation" render={() => <Installation title={this.state.installation.title} />} />
          <Route path="/monitoring" render={() => <Monitoring title={this.state.monitoring.title} />} />
          <Route path="/repair" render={() => <Repair title={this.state.repair.title} />} />

          <Footer />

        </Container>
      </Router>
    );
  }
} 

export default App;
