import React from 'react';
import '../styles.css'

import NFPAlogo from '../../components/icons/NFPAlogo.png';
import NICETlogo from '../../components/icons/NICETlogo.png';
import BICSIlogo from '../../components/icons/BICSIlogo.png';
import NSCAlogo from '../../components/icons/NSCAlogo.png';
import ESAlogo from '../../components/icons/ESAlogo.png';
import LLSSAlogo from '../../components/icons/LLSSAlogo.png';
import AFAAlogo from '../../components/icons/AFAAlogo.png';
import NFIBlogo from '../../components/icons/NFIBlogo.png';
import LNHAlogo from '../../components/icons/LNHAlogo.png';
import SCIAlogo from '../../components/icons/SCIAlogo.png';
import TerrComm from '../../components/icons/TerrCommlogo.jpg';
import LafComm from '../../components/icons/LafCommlogo.jpg';
import MaryComm from '../../components/icons/MaryCommlogo.png';


function Organizations(props) {
    document.title = "Organizations";
    return (
    <body className="subBody">
        <p>
        <div className="heading">Organizations<hr className="headerLine"></hr></div>
        <div className="paragraph4">A A R Electronics is partnered with numerous groups across the state to ensure that each of our jobs meet operational guidelines and provide a safe environment on the site. Provided below are several of the the organizations in which we work closely with.
     </div>
        <div className="pageBody">
        <table className="certTable">
            <tr>
                <td className="orgCell"><a href="https://www.nfpa.org/"><img src={NFPAlogo} alt="NFPA" width="40%"></img></a></td>
                <td className="orgCell"><a href="https://www.nicet.org/"><img src={NICETlogo} alt="NICET" width="40%"></img></a></td>
                <td className="orgCell"><a href="https://www.bicsi.org/"><img src={BICSIlogo} alt="BICSI" width="50%"></img></a></td>
                <td className="orgCell"><a href="https://www.nsca.org/"><img src={NSCAlogo} alt="NSCA" width="100%"></img></a></td>
            </tr>
            <tr>
                <td className="orgCell"><a href="https://esaweb.org/"><img src={ESAlogo} alt="ESA" width="70%"></img></a></td>
                <td className="orgCell"><a href="https://llssa.org/"><img src={LLSSAlogo} alt="LLSSA" width="60%"></img></a></td>
                <td className="orgCell"><a href="http://laafaa.org/"><img src={AFAAlogo}alt="AFAA" width="60%"></img></a></td>
                <td className="orgCell"><a href="https://www.nfib.com/louisiana/"><img src={NFIBlogo} alt="NFIB" width="70%"></img></a></td>
            </tr>
            <tr>
                <td className="orgCell"><a href="https://www.lnha.org/"><img src={LNHAlogo} alt="LNHA" width="45%"></img></a></td>
                <td className="orgCell"><a href="https://www.sciaonline.net/"><img src={SCIAlogo} alt="SCIA" width="80%"></img></a></td>
                <td className="orgCell"><a href="https://houmachamber.com/"><img src={TerrComm} alt="Terrebonne Chamber of Commerce" width="90%"></img></a></td>
                <td className="orgCell"><a href="http://www.lafourchechamber.com/"><img src={LafComm} alt="Lafourche Chamber of Commerce" width="90%"></img></a></td>
            </tr>
            </table><table>
            <tr>
            <td className="orgCell"><a href="https://stmarychamber.com/"><img src={MaryComm} alt="St. Mary Chamber of Commerce" width="30%"></img></a></td>
            </tr>
        </table>
        </div>
        </p>
    </body>
    );
}

export default Organizations;