import React from 'react';
import '../styles.css'

import CameraDiagram from '../../components/products/analogcameras.png'
import Panasonic from '../../components/products/panasonic.jpg'
import NVR from '../../components/products/nvr.png'

function Cameras(props) {
    document.title = "Cameras";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Camera Systems<hr className="headerLine"></hr></div>
        <div className="pageBody">
            <table className="infoTable">
                <tr>
                    <td className="productCell">
                    <div className="productHead">Panasonic<hr className="productDiv"></hr></div>
                    <div className="product">Network Video Recorder Hardware</div>
                        <div>Our NVRs come pre-loaded, activated, and tested with the full Video Insight Software Suite. The "plug-and-play" NVRs are ready immediately out-of-the box for a quick start to record and view your cameras.
                        We have a wide variety of NVR configurations available based on the number of IP cameras, the amount
                        of storage, and the processing capacity. Both towers and rack mounts are available.
                        <ul><h5 className="productList">Key Benefits</h5>
                        <li>Plug-and-Play: Ready to start recording / Viewing cameras</li>
                        <li>Easy Deployment</li>
                        <li>IP licenses activated (If included)</li>
                        <li>Tested and fully-configured</li>
                        <li>Superior US-based technical support</li>
                        <li>1-Year hardware limited warranty</li>
                        <li>Unlimited Software Updates for included Video Insight software.</li>
                        </ul><br></br>
                            <a className="contentLinks" href="https://www.security.us.panasonic.com/video-management-software/network-video-recorder-hardware">Product Link</a></div>
                    </td>
                    <td className="productImg"><img className="images2" src={Panasonic} alt="Panasonic" width="110%"></img></td>
                </tr>
                <tr>
                    <td className="productCell">
                    <div className="productHead">Small IP System<hr className="productDiv"></hr></div>
                    <div className="product">16-Channel NVR</div>
                    <div>16 Ch. real time 3MP resolution PoE NVRs, which adopt the most advanced SOC technique to ensure
                    high definition recording in each channel. This series integrate with 16 PoE network ports, direct
                    connection to NVR, support simultaneous 4 CH playback, and HDMI 1080P high resolution display. The
                    series of product can meet different security requirements of commerce, and government, etc.<br></br></div>
                    </td>
                    <td className="productImg"><img className="images2" src={NVR} alt="NVR" width="110%"></img></td>
                </tr>
                </table>
                <table className="infoTable">
                 <tr>
                    <td className="productCell3">
                    <div className="productHead">Analog System<hr className="productDiv"></hr></div>
                    <div className="product">4, 8, and 16-Channel DVR</div>
                        <div>With the surveillance operators pursuing to HD/UHD which further adds the storage and transmission
                        cost, HDCVI H.265+/H.265 has been released to incredibly decrease the required bitrate while ensuring
                        the video quality in realizing high resolution surveillance such as 4MP/4K. The Lite Series is designed for
                        high cost performance and quality. The range offers a great solution for users facing budget constraints
                        who both require quality and performance. And with the adoption of Smart H.265+/H.265, the series
                        improves encoding efficiency, saving on bandwidth/storage costs, and significantly reducing the Total
                        Cost of Ownership.<br></br></div>
                    </td>
                </tr>
        </table>
        <div className="bottomImg"><img src={CameraDiagram} alt="Camera Diagram" width="60%"></img></div>
        </div>
        </p>
    </body>
    );
}

export default Cameras;