import React from 'react';
import '../styles.css'

import AMT15 from '../../components/products/AMT.jpg';

function PublicAddress(props) {
    document.title = "Public Address";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Public Address Systems<hr className="headerLine"></hr></div>
        <div className="pageBody">
            <table className="infoTable">
            <tr><td><div className="productHead">High Output Speakers<hr className="prouctDiv"></hr></div></td></tr>
                <tr>
                    <td className="productCell">
                    <div className="product">Gymnasiums & Auditoriums</div>
                    Gymnasium sound systems are designed for music and vocal clarity in gyms, basketball courts,
                    volleyball courts, and all types of school gyms and athletic facilities. Speakers can be installed
                    by wall mount or hanging from the ceiling. In addition, our systems include options for
                    Bluetooth wireless and wall mount controls.<br></br>
                    </td>
                </tr>
                <tr>
                    <td className="productCell">
                    <div className="product">Stadiums & Outdoor Recreation</div>
                    Stadium sound systems are designed for music and vocal clarity at football fields, baseball
                    fields, and all types of school athletic facilities. Weather resistant Community speakers can be
                    installed by wall mount or pole mount. In addition, our systems include options for Bluetooth
                    wireless and wall mount controls.</td>
                </tr>
        </table>
        <div className="bottomImg2"><img className="images3" src={AMT15} alt="AMT15" width="35%"></img><br></br>
        <h4><i>Bogen AMT-15 Loudspeakers</i></h4>
        <a className="contentLinks" href="http://www.bogen.com/products/highoutputspeakers/">Product Link</a></div>
        
        </div>
        </p>
    </body>
    );
}

export default PublicAddress;