import React from 'react';
import '../styles.css'

import BusinessImg from '../../components/markets/business.jpg'

function Business(props) {
    document.title = "Businesses";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Businesses<hr className="headerLine"></hr></div>
        <div className="paragraph4">
        The following are examples of the services we have provided on various small businesses and companies throughout the state.<br></br>
        <b><u>If the service you need does not fall under these categories, please contact us for inquiries.</u></b>
        </div>
        <table className="infoTable">
            <tr><td className="productCell4">
        <ul className="marketList">
            <li> <a href="\telephone">Telephone Systems</a></li>
            <li> <a href="\intercom">Intercom Systems</a></li>
            <li> <a href="\cameras">Camera Systems</a></li>
            <li> <a href="\accesscontrol">Access Control Systems</a></li>
            <li> <a href="\avsystems">Audio & Video Systems</a></li>
            <li> <a href="\networking">Network Cabling and Management</a></li>
        </ul>
            </td>
            <td className="productImg"><img className="images" src={BusinessImg} alt="Business" width="70%"></img></td>
            </tr>
        </table>
        </p>
    </body>
    );
}

export default Business;