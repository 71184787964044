import React from 'react';
import Logo from '../components/aarlogo.png';
import './styles.css'
import Email from '../components/icons/contact.png';

function Header() {

   return(
      <header className="header">
         <a className= "a" href="/"><img src={Logo} alt="website logo" width="450px" height="auto" /></a>
      <object align = "right">
         <table>
            <tr>
               <td className="top">
               <a href="mailto:aarelect@aaremail.com"><img src={Email} alt="Contact Us"width="85px" height="auto"  /><br></br>Contact Us</a>
               </td>
            </tr>
         </table>
   </object>
     </header>
   );
}


export default Header;