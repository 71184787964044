import React from 'react';
import '../styles.css'

function FindUs(props) {
    document.title = "Find Us";
    return (
    <body className="subBody">
        <p>
        <div className="heading">Find Us<hr className="headerLine"></hr></div>
        <div className="paragraph2">
            <table className="findusTable">
                <tr>
            <td className="findusCell"><b>Physical Address:</b> 1620 Coteau Rd, Houma, Louisiana 70364<br></br>
            <b>Mailing Address:</b> PO Box 4336, Houma, Louisiana 70360
            </td>
                </tr>
            </table>
            <hr></hr>
        </div>
        <div className="pageBody">
        <iframe title = "map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3468.3256949532497!2d-90.69204368489474!3d29.623282382037562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x862105309440578b%3A0xdcae0d876545ac8e!2sAAR%20Electronics%2C%20INC!5e0!3m2!1sen!2sus!4v1583041720777!5m2!1sen!2sus" width="100%" height="450" frameborder="0" allowfullscreen="true"></iframe>
        </div>
        </p>
    </body>
    );
}

export default FindUs;