import React from 'react';
import '../styles.css'

import TX3 from '../../components/products/TX3-CX.jpg'
import VI from '../../components/icons/VIlogo.png'

function AccessControl(props) {
    document.title = "Access Control";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Access Control<hr className="headerLine"></hr></div>
        <div className="pageBody">
            <table className="infoTable">
                <tr>
                    <td className="productCell">
                    <div className="productHead">Mircom<hr className="productDiv"></hr></div>
                    <div className="product">Small-Scale Access Control</div>
                        <div>The TX3-CX-2-A controller has a unique feature of being compatible to the entire family of TX3 platform
                        solutions from voice entry, elevator restriction and wireless Aperio locksets. Unlike other platforms, the
                        TX3 will meet the basic job requirements and accommodate other elements of an intelligent building as
                        the needs arises.<br></br>
                            <a className="contentLinks" href="https://mircom.com/product-listing/access-control/door-access-controllers/tx3-cx-two-door-access-control-controller/">Product Link</a></div>
                    </td>
                    <td className="productImg"><img src={TX3} alt="Mircom TX3" width="80%"></img></td>
                </tr>
                <tr>
                    <td className="productCell">
                    <div className="productHead">Panasonic<hr className="productDiv"></hr></div>
                    <div className="product">Video Insight & MonitorCast</div>
                        <div>Door Access Levels and Management, Unlimited Client Connections,
                        Alarm Alert Email Notification, Secure Browser Login Map Based Alarms
                        (With VI Monitor Integration), Active Directory Integration, Anti-Pass back,
                        Built in Rules Manager, Dual Card Authentication Multi-Site Support,
                        Occupancy Control, Database Trimmer.<br></br>
                            <a className="contentLinks" href="https://www.security.us.panasonic.com/applications/access-control/">Product Link</a></div>
                    </td>
                    <td className="productImg"><img src={VI} alt="Video Insight Logo" width="60%"></img></td>
                </tr>
        </table>
        </div>
        </p>
    </body>
    );
}

export default AccessControl;