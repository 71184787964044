import React from 'react';
import '../styles.css'

import InspectionImg from '../../components/services/inspectionImg.jpg'

function Inspection(props) {
    document.title = "Inspections";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Inspections<hr className="headerLine"></hr></div>
        <div className="paragraph4">
        We provide code required inspections of your Fire Alarm and Access Control
        system the meets and exceeds the requirements of NFPA and the local
        jurisdiction.
        </div>

        <div className="bottomImg3"> <img className="images" src={InspectionImg} alt="Inspection" width="25%"></img></div>

        </p>
    </body>
    );
}

export default Inspection;