import React from 'react';
import '../styles.css'

import QUANTUM from '../../components/products/quantum.jpeg';
import PAGING from '../../components/products/paging.jpg';

function IntercomSystems(props) {
    document.title = "Intercom Systems";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Intercom Systems<hr className="headerLine"></hr></div>
        <div className="pageBody">
            <table className="infoTable">
            <tr><td><div className="productHead">Educational Systems<hr className="prouctDiv"></hr></div></td></tr>
                <tr>
                    <td className="productCell">
                    <div className="product">Multicom 2000</div>
                    Traditional Classroom Intercom System featuring the basic
                    features of any educational Facility.<br></br>
                    <a className="contentLinks" href="http://www.bogenedu.com/multicom-analog-systems/">Product Link</a>
                    <div className="product">Quantum</div>
                    Hybrid IP System with all of the features and architecture
                    of a traditional intercom system with additional IP features.
                    Synchronization to time servers, Multi-Node applications for large
                campuses, support IP phones and more.<br></br>
                <a className="contentLinks" href="http://www.bogenedu.com/quantum-hybrid-systems/">Product Link</a>
                    </td>
                    <td><img className="images" src={QUANTUM} alt="QUANTUM" width="87%"></img></td>
                </tr>
                <tr><td><div className="productHead">General Paging Systems<hr className="prouctDiv"></hr></div></td></tr>
                <tr>
                    <td className="productCell">
                    <ul>
                        <li>Features a complete line of general Audio and Paging products. Amplifiers,
                        Microphones, Mixers, Telephone Paging and more.</li>
                        <li>Products are available from manufactures such Bogen, Electro-Voice,
                        Atlas, Lowell Crown, BI-Amp and many more.</li>
                    </ul>
                    </td>
                    <td><img className="images2" src={PAGING} alt="PAGING" width="100%"></img></td>
                </tr>
        </table>
        </div>
        </p>
    </body>
    );
}

export default IntercomSystems;