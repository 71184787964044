import React from 'react';
import '../styles.css'

import P25 from '../../components/products/nx5200.jpg';

function WirelessComm(props) {
    document.title = "Wireless Communications";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Wireless Communications<hr className="headerLine"></hr></div>
        <div className="pageBody">
            <table className="infoTable">
            <tr><td><div className="productHead">Portable Radios<hr className="prouctDiv"></hr></div></td></tr>
                <tr>
                    <td className="productCell">
                    <div className="product">Facility Communications</div>
                    Two-way radios help provide communications for all forms of business and education. They provide ease of use and affordability while also increasing productivity and enhancing security for K-12 schools, office buildings, and industrial yards.<br></br>
                    </td>
                </tr>
                <tr>
                    <td className="productCell">
                    <div className="product">Public Safety Communications</div>
                    P25 radios help provide an interoperable two-way digital communication platform, which is universal between all agencies and ensures compliance between various radio ecosystems and manufacturers products.</td>
                </tr>
        </table>
        <div className="bottomImg2"><img className="images" src={P25} alt="P25" width="45%"></img><br></br>
        <h4><i>Kenwood NX-5200</i></h4>
        <a className="contentLinks" href="https://comms.kenwood.com/en/products/model.php?ID=NX-5200-a&category=8">Product Link</a></div>
        
        </div>
        </p>
    </body>
    );
}

export default WirelessComm;