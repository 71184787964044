import React from 'react';
import '../styles.css'

import taiden1 from '../../components/products/taiden1.jpg'
import AV1 from '../../components/products/AV1.jpg'
import AV2 from '../../components/products/AV2.jpg'

function AVSystems(props) {
    document.title = "A/V Systems";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Audio & Video Systems<hr className="headerLine"></hr></div>
        <div className="pageBody">
        <div className="paragraph3">Audio/Visual (A/V) systems can be comprised of an assortment of hardware that includes
                        projectors, televisions, video cameras, interactive whiteboards, computers, sound equipment, and
                        more. These systems are flexible enough to be as simple or complex as needed.</div>
                        <table className="infoTable">
                <tr>
                    <td className="productCell">
                    <div className="productHead">TAIDEN<hr className="productDiv"></hr></div>
                    <div>TAIDEN owns high-end technologies of conference system and provides solutions for meetings,
                    from small installations to international congresses &amp; conventions. These systems are can incorporate
                    voting, recording, presentations, and more.<br></br>
                    <a className="contentLinks" href="http://www.taiden.com/products_detail/productId=1007.html">Product Link</a></div>
                    </td>
                    <td className="productImg"><img className="images2" src={taiden1} alt="Taiden System" width="80%"></img></td>
                </tr>
        </table>
                    <div className="productInline">
                    <div className="productHead"><u>Systems Provided For:</u></div>
                    <ul className="AVList">
                        <h4><li>Small Conference Rooms</li>
                        <li>Large Conference Rooms</li>
                        <li>Integrated Systems</li>
                        <li>Board Room Systems</li>
                        <li>. . . and more</li></h4>
                        </ul><br></br>
                    </div>
<table>
    <tr>
                <td><div className="bottomImg"><img className="images" src={AV1} alt="Audio & Video" width="75%"></img></div></td>
                <td><div className="bottomImg"><img className="images" src={AV2} alt="Audio & Video" width="75%"></img></div></td>
    </tr>
    </table>
        </div>
        </p>
    </body>
    );
}

export default AVSystems;