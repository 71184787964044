import React from 'react';
import  Jumbotron  from 'react-bootstrap/Jumbotron';
import  Container  from 'react-bootstrap/Container';
import  Row  from 'react-bootstrap/Row';
import  Col  from 'react-bootstrap/Col';
import './styles.css'

function Hero(props) {
   return(
      <Jumbotron className="jumbotron-fluid position-relative">
         <Container fluid={false}>
            <Row className="justify-content-center py-3 position-static">
               <Col md={13} sm={12}>
                  { props.title && <h1 className="display-3
                  font-weight-bolder">{props.title} </h1> }
                  { props.subTitle && <h3 className="display-4 font-weight-light">{props.subtitle} </h3> }
                  { props.text && <h3 className="lead font-weight-light">{props.text} </h3> }
               </Col>
            </Row>
         </Container>
      </Jumbotron>
   );



}

export default Hero;
