import React from 'react';
import '../styles.css'

import Data from '../../components/products/data.jpg';

function NetworkCabling(props) {
    document.title = "Network Cabling";
    return (
    <body className="subBody">
        <p>
        <div className="heading">Network Cabling<hr className="headerLine"></hr></div>
        <div className="paragraph">
        Provides a complete network solution for any application. Including but not limit
    to isolated networks for any IP based technology, Fiber Optic cabling and
    terminations, Equipment racks, structured cabling system all installed in a neat
    organized manner meeting industry standards and the requirements of each
    customers technology standards.
        </div>
        <table className="infoTable">
                <tr>
                    <td className="productCell">
                    <div className="product">West Penn Wire</div>
                    CAT6 and Fiber Optic cables frequently ordered from West Penn Wire.<br></br>
                <a className="contentLinks" href="https://www.westpennwire.com/">Product Link</a>
                    </td>
                    <td><img className="images"src={Data} alt="Data Cables" width="100%"></img></td>
                </tr>
        </table>
        </p>
    </body>
    );
}

export default NetworkCabling;