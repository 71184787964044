import React from 'react';
import Hero from '../components/Hero';
import Shop from '../components/aarShop.jpg';
import './styles.css'

import Icon1 from '../components/icons/fire-alarm.png';
import Icon2 from '../components/icons/security.png';
import Icon3 from '../components/icons/communications.png';
import Icon4 from '../components/icons/audio.png';

function HomePage(props) {
document.title = "AAR Electronics, INC.";
   return( 
   <body className="homeBody">   
      <Hero title={props.title} subTitle={props.subTitle} text={props.text}  />
      <div className="homeContent">
         <p>
         <table id="homeInfo">
            <tr>
               <td className="homeCell"> <h1>About Us<hr></hr></h1>
                  <div className="homeInfo">A A R Electronics is an electronics systems contractor providing electronics systems solutions for commerical business throughout Louisiana for more than 30 years. We provide complete solutions from small facilities to larger, more complex facilities.<br></br>
                  <br></br>Our staff of certified knowledgable technicians will provide you with the solutions for customized for the customer's needs. Sales, construction, service, and installations; we can assist in providing you with the tools required to get your business up and running or to incorporate newer technologies. </div>
               </td>
               <td className="homeImg"><img className="images" src={Shop} alt="A A R Building" width="100%"></img></td>
            </tr>
         </table>
         <div className="homeLinks">
               <div className="linkCat">
                  <div className="linkTiles"><a className="iconNav" href="/firealarm"><img src={Icon1} alt="Fire Alarm Icon" align="center" width="115px" height="115px"/><br></br>
               Fire Alarm</a></div>
               </div>
               <div className="linkCat">
                  <div className="linkTiles"><a className="iconNav" href="/cameras"><img src={Icon2} alt="Security Icon" align="center" width="115px" height="115px"/><br></br>
               Security</a></div>
               </div>
               <div className="linkCat">
                  <div className="linkTiles"><a className="iconNav"  href="/telephone"><img src={Icon3} alt="Telephone Icon" align="center" width="115px" height="115px"/><br></br>
               Communication</a></div>
               </div>
               <div className="linkCat">
                  <div className="linkTiles"><a className="iconNav" href="/avsystems"><img src={Icon4} alt="Audio Icon" align="center" width="115px" height="115px"/><br></br>
               Audio</a></div>
               </div>
         </div>
         </p>
      </div>
   </body>  
      );



}

export default HomePage
