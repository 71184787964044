import React from 'react';
import '../styles.css'

import Sangoma1 from '../../components/products/sangoma.png'
import Sangoma2 from '../../components/products/sangoma2.png'
import Sangoma3 from '../../components/products/sangoma3.png'

function TelephoneSystems(props) {
    document.title = "Telephone Systems";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Telephone Systems<hr className="headerLine"></hr></div>
        <div className="pageBody">
            <table className="infoTable">
                <tr>
                    <td className="productCell2">
                    <div className="productHead">Phone Switch<hr className="productDiv"></hr></div>
                     <div>On-site solution is the best route for most businesses. Sangoma can offer
                            on-site solutions from the smallest of systems with only a few phones to
                            larger corporations with hundreds of phones. On-site systems give you
                            control of your system and the peace of mind that is the internet is out you
                            can still communicate with your customers.<br></br>
                            <a className="contentLinks" href="https://www.sangoma.com/voip-security/session-border-controllers/">Product Link</a></div>
                    </td>
                    <td className="productImg"><img className="images2" src={Sangoma1} alt="Sangoma System" width="100%"></img></td>
                </tr>
                <tr>
                    <td className="productCell2">
                    <div className="productHead">Cloud System<hr className="productDiv"></hr></div>
                    <div>Cloud based systems are available and is ideal for small businesses. Cloud
                    based system offers a featured of an on-site system without the equipment investment.<br></br>
                    <a className="contentLinks" href="https://www.sangoma.com/cloud-services/">Product Link</a> </div>
                    </td>
                    <td className="productImg"><img className="images2" src={Sangoma3} alt="Sangoma System" width="80%"></img></td>
                </tr>
                <tr>
                    <td className="productCell2">
                    <div className="productHead">Telephone Handsets<hr className="productDiv"></hr></div>
                    <div>Sangoma offers a large variety of handsets to meet your
                    needs. From the simplest of phones to multi-line and color display with Bluetooth.<br></br>
                    <a className="contentLinks" href="https://www.sangoma.com/ip-phones/">Product Link</a></div>
                    </td>
                    <td className="productImg"><img className="images2" src={Sangoma2} alt="Sangoma System" width="85%"></img></td>
                </tr>
        </table>
        </div>
        </p>
    </body>
    );
}

export default TelephoneSystems;