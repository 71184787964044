import React from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col} from 'react-bootstrap';
import './styles.css'

function Footer() {

   return(
      <footer className="footer">
         <Container fluid={true}>
            <Row className="row"> 
               <Col xs={2}>
                  <h5 class="font weight-bold text-uppercase mt-4">A A R ELECTRONICS, INC.</h5>
                  1620 Coteau Rd <br></br>
                  Houma, Louisiana 70364 <br></br>
                  Phone: <a href="tel:9858764096">(985) 876-4096</a> <br></br>
                  Fax: <a href="tel:9858530134">(985) 853-0134</a> <br></br>
                  Email: <a href="mailto:general@aaremail.com"> general@aaremail.com </a><br></br>
               </Col>
               
               <Col className= "text-left">
                  <h5 class="font-weight-bold text-uppercase mt-4 mb-2">Products</h5><hr className="footDiv"></hr>
                  <ul id class="list-unstyled">
                     <li><a className="footLinks" href="/firealarm">Fire Alarm</a></li>
                     <li><a className="footLinks" href="/cameras">Cameras</a></li>
                     <li><a className="footLinks" href="/accesscontrol">Access Control</a></li>
                     <li><a className="footLinks" href="/intercom">Intercom Systems</a></li>
                     <li><a className="footLinks" href="/publicaddress">Public Address</a></li>
                     <li><a className="footLinks" href="/avsystems">A/V Systems</a></li>
                     <li><a className="footLinks" href="/nursecall">Nurse Call</a></li>
                     <li><a className="footLinks" href="/telephone">Telephone Systems</a></li>
                     <li><a className="footLinks" href="/networking">Network Cabling</a></li>
                     <li><a className="footLinks" href="/wireless">Wireless Communications</a></li>
                  </ul>
               </Col>

               <Col className= "text-left" >
                  <h5 class="font-weight-bold text-uppercase mt-4 mb-2">Markets</h5><hr className="footDiv"></hr>
                  <ul class="list-unstyled">
                     <li><a className="footLinks" href="educational">Educational</a></li>
                     <li><a className="footLinks" href="healthcare">Healthcare</a></li>
                     <li><a className="footLinks" href="government">Government</a></li>
                     <li><a className="footLinks" href="religious">Religious</a></li>
                     <li><a className="footLinks" href="institutional">Institutional</a></li>
                     <li><a className="footLinks" href="business">Business</a></li>
                     <li><a className="footLinks" href="retail">Retail</a></li>
                  </ul>
               </Col>

               <Col className= "text-left">
                  <h5 class="font-weight-bold text-uppercase mt-4 mb-2">Services</h5><hr className="footDiv"></hr>
                  <ul class="list-unstyled">
                     <li><a className="footLinks" href="installation">Installation</a></li>
                     <li><a className="footLinks" href="repair">Repair</a></li>
                     <li><a className="footLinks" href="inspection">Inspection</a></li>
                     <li><a className="footLinks" href="monitoring">Monitoring</a></li>
                  </ul>
               </Col>

               <Col className= "text-left">
                  <h5 class="font-weight-bold text-uppercase mt-4 mb-2">About Us</h5><hr className="footDiv"></hr>
                  <ul class="list-unstyled">
                     <li><a className="footLinks" href="findus">Find Us</a></li>
                     <li><a className="footLinks" href="ourteam">Our Team</a></li>
                     <li><a className="footLinks" href="certifications">Certifications</a></li>
                     <li><a className="footLinks" href="organizations">Organizations</a></li>
                  </ul>
               </Col>

            </Row>
            <div className="trademark">©2020 A A R Electronics, INC.<br></br>
            <a className="bottomText" href="https://pngtree.com/free-backgrounds">Free background photos from pngtree.com</a></div>
         </Container>
      </footer>
   );
}

export default Footer;
