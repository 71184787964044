import React from 'react';
import Portrait1 from '../../components/portraits/portrait1.jpg';
import Portrait2 from '../../components/portraits/portrait2.jpg';
import Portrait3 from '../../components/portraits/portrait3.JPG';
import Portrait4 from '../../components/portraits/portrait4.jpg';
import Portrait5 from '../../components/portraits/portrait5.jpg';
import Portrait6 from '../../components/portraits/portrait6.png';
import Portrait7 from '../../components/portraits/portrait7.jpg';
import Portrait8 from '../../components/portraits/portrait8.jpg';
import Portrait9 from '../../components/portraits/portrait9.jpg';
import Portrait10 from '../../components/portraits/portrait10.jpg';

function OurTeam(props) {
document.title = "Our Team";
   return(
   <body className="subBody">
      <p>
      <div className="heading">Our Team</div><hr className="headerLine"></hr>
     <div className="paragraph">In our effort to provide the utmost in customer relations, A A R Electronics has formed a team of qualified professionals to ensure your complete satisfaction with the products you have purchased and the installation, training, and service you deserve. Our NICET-certified technicians are fully trained to make the installation process a success.
     </div>
   <div className="employeeTable">
       <div className="table_cell">
          <img className="employeeImg" src={Portrait1} alt="portrait1" align="center" width="200px" height="200px"/></div>
          <div className="table_cell2">
             <div className="tableHead">Corey Fontenot</div><br></br>
            <div className="tableContent">
               Vice President<br></br>
               <b>Phone:</b> <a href="tel:+9858764096">(985) 876-4096 ext. 205</a><br></br>
               <b>Email:</b> <a href="mailto:corey@aaremail.com">corey@aaremail.com</a></div><br></br>
            <div></div>
         </div>
       <div className = "table_cell">
          <img className="employeeImg" src={Portrait2} alt="portrait2" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Ryan Rodrigue</div><br></br>
            <div className="tableContent">
               Service & Technology Manager<br></br>
               <b>Phone:</b> <a href="tel:+9858764096">(985) 876-4096 ext. 221</a><br></br>
               <b>Email:</b> <a href="mailto:ryan@aaremail.com">ryan@aaremail.com</a><br></br>
            </div> 
          </div>
         <br></br>
         <div className="table_cell">
         <img className="employeeImg" src={Portrait3} alt="portrait3" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Chase Sonier</div><br></br>
            <div className="tableContent">
               Estimator & Project Manager<br></br>
               <b>Phone:</b> <a href="tel:+9858764096">(985) 876-4096 ext. 202</a><br></br>
               <b>Email:</b> <a href="mailto:chase@aaremail.com">chase@aaremail.com</a><br></br>
               </div>
          </div>
          <div className="table_cell">
         <img className="employeeImg" src={Portrait4} alt="portrait4" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Richard Songe</div><br></br>
            <div className="tableContent">
               Estimator & Project Manager<br></br>
               <b>Phone:</b> <a href="tel:+9858764096">(985) 876-4096 ext. 201</a> <br></br>
               <b>Email:</b> <a href="mailto:richard@aaremail.com">richard@aaremail.com</a>
            </div> 
          </div>
          <br></br>
          <div className="table_cell">
         <img className="employeeImg" src={Portrait5} alt="portrait5" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Mike Portier</div><br></br>
            <div className="tableContent">
               Outside Sales<br></br>
               <b>Phone:</b> <a href="tel:+9858764096">(985) 876-4096 ext. 223</a><br></br>
               <b>Email:</b> <a href="mailto:mike@aaremail.com">mike@aaremail.com</a>
               </div> 
          </div>
          <div className="table_cell">
         <img className="employeeImg" src={Portrait6} alt="portrait6" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Lamar Caskey</div><br></br>
            <div className="tableContent">
               Outside Sales<br></br>
            <b>Phone:</b> <a href="tel:+9858764096">(985) 876-4096 ext. 225</a><br></br>
            <b>Email:</b> <a href="mailto:lamar@aaremail.com">lamar@aaremail.com</a>
            </div> 
          </div>
          <br></br>
          <div className = "table_cell">
          <img className="employeeImg" src={Portrait7} alt="portrait7" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Dave Wallace</div><br></br>
            <div className="tableContent">
               Senior Technician<br></br>
               <b>Email:</b> <a href="mailto:dave@aaremail.com">dave@aaremail.com</a>
            </div> 
          </div>
          <div className="table_cell">
         <img className="employeeImg" src={Portrait8} alt="portrait8" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Anthony Boudreaux</div><br></br>
            <div className="tableContent">
               Service Technician<br></br>
            <b>Email:</b> <a href="mailto:anthony@aaremail.com">anthony@aaremail.com</a>
            </div> 
          </div>
          <br></br>
          <div className = "table_cell">
          <img className="employeeImg" src={Portrait9} alt="portrait9" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Todd Encardes</div><br></br>
            <div className="tableContent">
               Service Technician<br></br>
               <b>Email:</b> <a href="mailto:todd@aaremail.com">todd@aaremail.com</a>
            </div> 
          </div>
          <div className = "table_cell">
          <img className="employeeImg" src={Portrait10} alt="portrait10" align="center" width="200px" height="200px"/></div>
          <div className= "table_cell2">
            <div className="tableHead">Jason Pitre</div><br></br>
            <div className="tableContent">
               Wireless Communications Technician<br></br>
               <b>Email:</b> <a href="mailto:jason@aaremail.com">jason@aaremail.com</a>
            </div> 
          </div>
      </div>
   </p>
   </body>
   );



}

export default OurTeam
