import React from 'react';
import '../styles.css'

import EducationalImg from '../../components/markets/educational.jpg'

function Educational(props) {
    document.title = "Educational";
    return (
        <body className="subBody">
        <p>
        <div className="heading">Educational<hr className="headerLine"></hr></div>
        <div className="paragraph4">
        The following are examples of the services we have provided on various public schools, private schools, and universities throughout the state.<br></br>
        <b><u>If the service you need does not fall under these categories, please contact us for inquiries.</u></b>
        </div>
        <table className="infoTable">
            <tr><td className="productCell4">
        <ul className="marketList">
            <li> <a href="\firealarm">Fire Alarm</a></li>
            <li> <a href="\intercom">School Intercom</a></li>
            <li> <a href="\cameras">Camera Systems</a></li>
            <li> <a href="\accesscontrol">Access Control Systems</a></li>
            <li> Intrusion Alarms</li>
            <li> <a href="\publicaddress">Public Address Systems</a></li>
            <li> <a href="\telephone">Telephone Systems</a></li>
            <li> <a href="\networking">Network Cabling and Management</a></li>
            <li> <a href="\wirelesscomm">Wireless Communications</a></li>
        </ul>
            </td>
            <td className="productImg"><img className="images" src={EducationalImg} alt="Educational" width="70%"></img></td>
            </tr>
        </table>
        </p>
    </body>
    );
}

export default Educational;